<template>
  <div>
    <!-- Start Content  -->
    <b-modal
      v-model="modal.isShow"
      :title="$t('WITHDRAWAL.REQ_NEW_WITHDRAW')"
      @ok="submitModal"
    >
      <b-form @submit.stop.prevent="createWithdrawal" ref="form">
        <b-form-group :label="`${$t('TABLE.AMOUNT')}:`">
          <b-form-input
            type="number"
            :placeholder="$t('WITHDRAWAL.ENTER_AMOUNT')"
            v-model="modal.amount"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ $t("MODAL.FIELD_REQUIRED_DECIMAL") }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="`${$t('TABLE.RECEIVER_ADDRESS')}:`">
          <b-form-input
            :placeholder="$t('WITHDRAWAL.ENTER_RECEIVER')"
            v-model="modal.receiver_address"
          ></b-form-input>
        </b-form-group>
        <b-form-group :label="`${$t('WITHDRAWAL.CURRENCY')}:`">
          <b-form-select
            :placeholder="$t('WITHDRAWAL.CURRENCY')"
            :options="currencyType"
            v-model="modal.currency"
          ></b-form-select>
        </b-form-group>
        <b-form-group :label="`${$t('WITHDRAWAL.WITHDRAWAL_FEE')}:`">
          <b-form-input
            v-model="calculateWithdrawalFee"
            :disabled="true"
          ></b-form-input>
        </b-form-group>
        <b-form-group :label="`${$t('TABLE.RECEIVE_AMOUNT')}:`">
          <b-form-input
            v-model="calculateReceiveAmount"
            :disabled="true"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="ok()"
          >
            {{ $t("MODAL.OK") }}
          </b-button>
          <b-button
            variant="secondary"
            size="sm"
            class="float-right mr-2"
            @click="cancel()"
          >
            {{ $t("MODAL.CANCEL") }}
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      v-model="modalUpdate.isShow"
      :title="$t('WITHDRAWAL.UPDATE_STATUS')"
      @ok="submitModalUpdate"
    >
      <b-form @submit.stop.prevent="updateStatus" ref="form">
        <b-form-group
          :label="`${$t('WITHDRAWAL.TRX_ID')}:`"
          v-if="modalUpdate.data.status === 'PROCESSING'"
        >
          <b-form-input
            :placeholder="$t('WITHDRAWAL.ENTER_TRX_ID')"
            v-model="modalUpdate.trx_id"
          ></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('TABLE.STATUS') + ':'">
          <b-form-select
            :placeholder="$t('TABLE.STATUS')"
            :options="
              modalUpdate.data.status === 'PROCESSING'
                ? modalUpdate.statusProcess
                : modalUpdate.statusPending
            "
            v-model="modalUpdate.status"
          ></b-form-select>
        </b-form-group>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="ok()"
          >
            {{ $t("MODAL.OK") }}
          </b-button>
          <b-button
            variant="secondary"
            size="sm"
            class="float-right mr-2"
            @click="cancel()"
          >
            {{ $t("MODAL.CANCEL") }}
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- Start Header  -->
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-header border-0 py-3 page-title">
        <h3 class="card-title align-items-center">
          <span class="card-label font-weight-bolder text-dark">{{
            $t("MENU.WITHDRAWAL")
          }}</span>
        </h3>
      </div>
      <!-- End Header  -->
      <div class="card-body py-0">
        <!-- Start Search Filter -->
        <form class="form">
          <div class="row form-group">
            <div class="col-md-12">
              <div class="row pb-2 align-items-end">
                <div class="col-md-3">
                  <label class="form-label mb-1">{{
                    $t("WITHDRAWAL.START_UP_DATE")
                  }}</label>
                  <b-datepicker
                    :placeholder="$t('WITHDRAWAL.NO_DATE')"
                    size="sm"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'short',
                      day: '2-digit'
                    }"
                    today-button
                    reset-button
                    close-button
                    locale="en"
                    v-model="search.dateFrom"
                  ></b-datepicker>
                </div>

                <div class="col-md-3 align-items-bottom">
                  <label class="form-label mb-1">{{
                    $t("WITHDRAWAL.END_UP_DATE")
                  }}</label>
                  <b-datepicker
                    :placeholder="$t('WITHDRAWAL.NO_DATE')"
                    size="sm"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'short',
                      day: '2-digit'
                    }"
                    today-button
                    reset-button
                    close-button
                    locale="en"
                    v-model="search.dateTo"
                  ></b-datepicker>
                </div>

                <div class="col-md-2">
                  <label class="form-label mb-1">{{
                    $t("WITHDRAWAL.STATUS")
                  }}</label>
                  <b-select class="py-0" size="sm" v-model="search.status">
                    <b-select-option value="ALL">{{
                      $t("WITHDRAWAL.ALL")
                    }}</b-select-option>
                    <b-select-option value="PENDING">{{
                      $t("WITHDRAWAL.PENDING")
                    }}</b-select-option>
                    <b-select-option value="PROCESSING">{{
                      $t("WITHDRAWAL.PROCESSING")
                    }}</b-select-option>
                    <b-select-option value="SUCCESS">{{
                      $t("WITHDRAWAL.SUCCESS")
                    }}</b-select-option>
                    <b-select-option value="REJECTED">{{
                      $t("WITHDRAWAL.REJECTED")
                    }}</b-select-option>
                    <b-select-option value="FAILED">{{
                      $t("WITHDRAWAL.FAILED")
                    }}</b-select-option>
                  </b-select>
                </div>

                <div class="col-md-2">
                  <label class="form-label mb-1">{{
                    $t("WITHDRAWAL.NETWORK")
                  }}</label>
                  <b-select class="py-0" size="sm" v-model="search.network">
                    <b-select-option value="ALL">{{
                      $t("WITHDRAWAL.ALL")
                    }}</b-select-option>
                    <b-select-option value="ERC-20">ERC-20</b-select-option>
                    <b-select-option value="BEP-20">BEP-20</b-select-option>
                  </b-select>
                </div>

                <div class="col-md-3 pt-3">
                  <label class="form-label mb-1">{{
                    $t("WITHDRAWAL.TRX_ID")
                  }}</label>
                  <b-input size="sm" v-model="search.transId"></b-input>
                </div>

                <div class="col-md-3">
                  <label class="form-label mb-1">{{
                    $t("WITHDRAWAL.RECEIVER_ADDRESS")
                  }}</label>
                  <b-input size="sm" v-model="search.receiverAddress"></b-input>
                </div>

                <div class="col-md-3">
                  <b-button
                    variant="primary"
                    size="sm"
                    @click="getWithdrawal(1)"
                    >{{ $t("WITHDRAWAL.SEARCH") }}</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </form>
        <!-- End Search Filter  -->
      </div>
    </div>

    <!-- Start List  -->
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-body pb-0">
        <div class="d-flex justify-content-between align-items-start pb-3">
          <div>
            <label class="form-label">{{ $t("TABLE.PER_PAGE") }}</label>
            <b-select
              size="sm"
              v-model="pagination.limit"
              @change="getWithdrawal(1)"
            >
              <b-select-option value="25">25</b-select-option>
              <b-select-option value="50">50</b-select-option>
              <b-select-option value="100">100</b-select-option>
            </b-select>
          </div>
          <div>
            <b-button
              v-if="getAccess.includes('withdraw--CREATE')"
              size="sm"
              class="mr-3"
              variant="success"
              @click="showModalWithdrawal()"
              >{{ $t("TABLE.REQUEST") }}</b-button
            >
            <b-button
              variant="primary"
              v-if="items.length !== 0"
              size="sm"
              @click="exportMethod()"
              >{{ $t("WITHDRAWAL.EXPORT") }}</b-button
            >
          </div>
        </div>

        <b-table class="bg-white" striped hover :fields="fields" :items="items">
          <!-- Set No Data when none of item -->
          <template #bottom-row="items" v-if="items.length === 0">
            <b-td :colspan="fields.length" class="text-center">{{
              $t("TABLE.NO_DATA")
            }}</b-td>
          </template>

          <!-- A virtual column -->
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(trx_id)="data">
            <div v-if="data.trx_id.sender_address">
              ...{{ data.item.trx_id.substr(data.item.trx_id.length - 8) }}
              <a href="javascript:void(0)" @click="copy(data.item.trx_id)">
                <img src="@/assets/img/copy.png" width="15px" />
              </a>
            </div>
          </template>

          <template #cell(username)="data">
            {{ data.item.username }}
          </template>

          <template #cell(code)="data">
            ...{{ data.item.code.substr(data.item.code.length - 8) }}
            <a href="javascript:void(0)" @click="copy(data.item.code)">
              <img src="@/assets/img/copy.png" width="15px" />
            </a>
          </template>

          <template #cell(receiver_address)="data">
            {{
              data.item.receiver_address.length > 8
                ? "..." +
                  data.item.receiver_address.substr(
                    data.item.receiver_address.length - 8
                  )
                : data.item.receiver_address
            }}
            <a
              href="javascript:void(0)"
              @click="copy(data.item.receiver_address)"
            >
              <img src="@/assets/img/copy.png" width="15px" />
            </a>
          </template>

          <template #cell(withdrawal_fee)="data">
            {{
              `${data.item.withdrawal_fee} ${
                data.item.network_fee
                  ? "(-" + data.item.network_fee + ")"
                  : data.item.network_fee
              }`
            }}
          </template>

          <template #cell(status)="data">
            {{ $t(`TABLE.${data.item.status}`) }}
          </template>

          <template #cell(action)="data">
            <b-button
              size="sm"
              class=""
              variant="success"
              @click="showModalUpdate(data.item)"
              v-if="
                getAccess.includes('withdraw--UPDATE') &&
                currentTier.id == 'ADMIN' &&
                (data.item.status === 'PROCESSING' ||
                  data.item.status === 'PENDING')
              "
              >{{ $t("TABLE.UPDATE") }}</b-button
            >
          </template>
        </b-table>
        <!-- Start Pagination  -->
        <div class="overflow-auto">
          <b-pagination
            v-model="pagination.page"
            :per-page="pagination.limit"
            :total-rows="pagination.totalRow"
            aria-controls="my-table"
            @change="getWithdrawal"
          ></b-pagination>
        </div>
        <!-- End Pagination  -->
      </div>
    </div>
    <!-- End List  -->
    <!-- End Content  -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

import {
  GET_WITHDRAWAL,
  CREATE_WITHDRAWAL,
  UPDATE_STATUS,
  GET_FEE,
  EXPORT_DATA
} from "@/core/services/store/withdrawal.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";
import globalMixin from "@/core/plugins/mixins.js";

export default {
  mixins: [globalMixin],
  data() {
    return {
      fields: [
        {
          key: "index",
          label: this.$t("TABLE.INDEX"),
          tdClass: "align-middle text-center",
          thClass: "text-center"
        },
        {
          key: "code",
          label: this.$t("TABLE.TRX_ID"),
          tdClass: "align-middle",
          thClass: "text-center"
        },
        {
          key: "username",
          label: this.$t("TABLE.USERNAME"),
          tdClass: "align-middle text-center",
          thClass: "text-center"
        },
        {
          key: "network",
          label: this.$t("TABLE.NETWORK"),
          tdClass: "align-middle text-center",
          thClass: "text-center"
        },
        {
          key: "receiver_address",
          label: this.$t("TABLE.RECEIVER_ADDRESS"),
          tdClass: "align-middle text-right",
          thClass: "text-center"
        },
        {
          key: "amount",
          label: this.$t("TABLE.AMOUNT"),
          tdClass: "align-middle text-right amount-column",
          thClass: "text-center"
        },
        {
          key: "withdrawal_fee",
          label: `${this.$t("WITHDRAWAL.WITHDRAWAL_FEE")} (${this.$t(
            "SETTING.INCLUDE_NETWORK_FEE"
          )})`,
          tdClass: "align-middle text-right amount-column fee-column",
          thClass: "text-center"
        },
        {
          key: "status",
          label: this.$t("TABLE.STATUS"),
          tdClass: "align-middle text-center",
          thClass: "text-center"
        },
        {
          key: "action",
          label: this.$t("TABLE.ACTION"),
          tdClass: "align-middle text-center",
          thClass: "text-center"
        }
      ],
      items: [],
      withdrawal_fee: {},
      currencyType: [
        { value: "USDT", text: "USDT (ERC20)", fee_key: "erc_wd_fee" },
        { value: "BUSD", text: "BUSD (BEP20)", fee_key: "bsc_wd_fee" }
      ],
      modal: {
        isShow: false,
        amount: 0,
        currency: "",
        receiver_address: ""
      },
      modalUpdate: {
        isShow: false,
        status: "",
        statusProcess: [
          {
            value: "SUCCESS",
            text: this.$t("TABLE.SUCCESS")
          },
          {
            value: "FAILED",
            text: this.$t("TABLE.FAILED")
          }
        ],
        statusPending: [
          {
            value: "PROCESSING",
            text: this.$t("TABLE.PROCESSING")
          },
          {
            value: "REJECTED",
            text: this.$t("TABLE.REJECTED")
          }
        ],
        trx_id: "",
        data: {}
      },
      search: {
        dateFrom: this.$moment().startOf("month").format("YYYY-MM-DD"),
        dateTo: this.$moment().format("YYYY-MM-DD"),
        status: "ALL",
        network: "ALL",
        transId: "",
        receiverAddress: ""
      },
      searchResult: {},
      pagination: {
        limit: 25,
        page: 1,
        totalRow: 0
      }
    };
  },
  computed: {
    ...mapGetters(["currentTier", "getAccess"]),
    getFeeKey() {
      const $self = this;
      return this.currencyType.find(function (e) {
        return e.value == $self.modal.currency;
      });
    },
    calculateWithdrawalFee() {
      try {
        const res =
          this.modal.amount * this.withdrawal_fee.fee +
          this.withdrawal_fee[this.getFeeKey.fee_key];
        return res > 0 ? res : 0;
      } catch (error) {
        return 0;
      }
    },
    calculateReceiveAmount() {
      try {
        const res = this.modal.amount - this.calculateWithdrawalFee;
        return res > 0 ? res : 0;
      } catch (error) {
        return 0;
      }
    }
  },
  mounted() {
    this.getWithdrawal();
    this.getWithdrawalFee();
  },
  methods: {
    exportMethod() {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      this.$store
        .dispatch(EXPORT_DATA, {
          limit: this.pagination.limit,
          page: this.pagination.page,
          ...this.searchResult
        })
        .then((e) => {
          const url = window.URL.createObjectURL(new Blob([e]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "withdrawal.csv"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          Swal.fire({
            text: this.translateErrorFromCode(err),
            type: "error",
            confirmButtonText: this.$t("MODAL.OK")
          });
        })
        .then(() => {
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        });
    },
    getWithdrawalFee() {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      this.$store
        .dispatch(GET_FEE)
        .then((e) => {
          this.withdrawal_fee = e;
        })
        .catch((err) => {
          Swal.fire({
            text: this.translateErrorFromCode(err),
            type: "error",
            confirmButtonText: this.$t("MODAL.OK")
          });
        })
        .then(() => {
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        });
    },
    getWithdrawal(page) {
      if (page) {
        this.pagination.page = page;
      }

      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      this.$store
        .dispatch(GET_WITHDRAWAL, {
          limit: this.pagination.limit,
          page: this.pagination.page,
          dateFrom: this.search.dateFrom,
          dateTo: this.search.dateTo,
          status: this.search.status,
          network: this.search.network,
          transId: this.search.transId,
          receiverAddress: this.search.receiverAddress
        })
        .then((e) => {
          this.items = e.data;
          this.pagination.totalRow = e.totalRow;
          this.pagination.limit = e.limit;

          // Set search for export
          this.searchResult = Object.assign({}, this.search);
        })
        .catch((err) => {
          Swal.fire({
            text: this.translateErrorFromCode(err),
            type: "error",
            confirmButtonText: this.$t("MODAL.OK")
          });
        })
        .then(() => {
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        });
    },
    showModalWithdrawal() {
      this.modal.amount = 0;
      this.modal.currency = "USDT";
      this.modal.receiver_address = "";
      this.modal.isShow = true;
    },
    showModalUpdate(dt) {
      this.modalUpdate.data = dt;
      this.modalUpdate.status = "";
      this.modalUpdate.trx_id = "";
      this.modalUpdate.isShow = true;
    },
    submitModal(modalEvent) {
      // Prevent modal from closing
      modalEvent.preventDefault();
      // Trigger submit handler
      this.createWithdrawal();
    },
    submitModalUpdate(modalEvent) {
      // Prevent modal from closing
      modalEvent.preventDefault();
      // Trigger submit handler
      this.updateStatus();
    },
    createWithdrawal() {
      const $self = this;
      if (this.calculateReceiveAmount <= 0) {
        Swal.fire({
          text: this.$t("WITHDRAWAL.AMOUNT_VALIDATION"),
          type: "error",
          confirmButtonText: this.$t("MODAL.OK")
        });

        return;
      } else {
        this.$store
          .dispatch(CREATE_WITHDRAWAL, {
            amount: this.calculateReceiveAmount,
            currency: this.modal.currency,
            receiver_address: this.modal.receiver_address
          })
          .then(() => {
            $self.modal.isShow = false;
            Swal.fire({
              text: this.$t("MODAL.CREATE_SUCESSFULL"),
              type: "success",
              confirmButtonText: this.$t("MODAL.OK")
            }).then(() => {
              $self.getWithdrawal(1);
            });
          })
          .catch((err) => {
            Swal.fire({
              text: this.translateErrorFromCode(err),
              type: "error",
              confirmButtonText: this.$t("MODAL.OK")
            });
          });
      }
    },
    updateStatus() {
      const $self = this;
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      this.$store
        .dispatch(UPDATE_STATUS, {
          code: this.modalUpdate.data.code,
          data: {
            trx_id: this.modalUpdate.trx_id,
            status: this.modalUpdate.status
          }
        })
        .then(() => {
          $self.modalUpdate.isShow = false;
          $self.getWithdrawal(1);
          Swal.fire({
            text: this.$t("MODAL.UPDATE_SUCESSFULL"),
            type: "success",
            confirmButtonText: this.$t("MODAL.OK")
          });
        })
        .catch((err) => {
          Swal.fire({
            text: this.translateErrorFromCode(err),
            type: "error",
            confirmButtonText: this.$t("MODAL.OK")
          });
        })
        .then(() => {
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        });
    }
  }
};
</script>
<style scoped lang="scss">
.page-title {
  min-height: auto !important;
}

::v-deep .amount-column {
  width: 50px;
}

::v-deep .fee-column {
  min-width: 165px;
}
</style>
